<script lang="ts"></script>

<template>
  <div class="powered-by">
    <span class="text">Powered by</span>
    <a href="https://wijsneus.app" class="logo" target="_blank"></a>
  </div>
</template>

<style lang="scss" scoped>
.powered-by {
  display: flex;
  align-items: center;
  gap: 0.3em;
  font-size: 0.9rem;
}

.text {
  opacity: 0.5;
}

.logo {
  width: 6.1em;
  height: 1em;
  background-color: currentColor;
  color: currentColor;
  mask-image: url("@/assets/images/wijsneus.svg");
  mask-repeat: no-repeat;
  mask-size: contain;
  margin-top: 0.1em;
}
</style>
